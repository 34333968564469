@import "~client_core/components/footer";
footer {
    .footer-item {
        &.collapsible-sm {
            .title {
                @include header(6);
                &:after {
                    top: 22px;
                }
            }
            .title,
            .content {
                    background: transparent;
            }
        }
    }
    .email-signup-form {
        .form-control {
            color: map-get($content, primary);
            &::placeholder {
                color: map-get($content, primary);
            }
            &:-webkit-autofill {
                box-shadow: 0 0 0 1000px map-get($bg, tertiary) inset;
                -webkit-text-fill-color: map-get($content, primary);
                border: none;
            }
        }
        .inline-btn:hover,
        .inline-btn:focus {
            border-color: $brand-primary;
        }
        .email-description {
            @include header(4);
            color: map-get($content, primary);
            margin-bottom: 8px;
        }

        .btn {
            @include icon($icon-arrow-top, after, 24px, map-get($content, primary));
            &::after {
                transform: rotate(90deg);
            }
            &::before {
                content:'';
            }
        }
    }
}

