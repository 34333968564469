@import "~client_core/components/menu";

.main-menu {
    &.menu-toggleable-left { 
        .nav-item { 
            ~ .nav-item {
                border-color: transparent;
            }
            .nav-link {
                @include navigation(1);
                &.user-message {
                    @include navigation(2);
                }
            }
        }
        .support {
            .dropdown-toggle {
                &.nav-link {
                    @include navigation(2);
                }
            }
        }
        .menu-subcategories-container {
            .dropdown-item + .dropdown-item {
                border-color: transparent;
            }
            .nav-link {
                @include navigation(3);
            }
            .menu-subcategories:after {
                display: none;
            }
        }
        .has-asset-1 .megamenu-asset-1 {
            padding: 0 $spacer;
            @include media-breakpoint-up(md) {
                padding: 0;
            }
            border-top: none;
        }
        .search-mobile .fa-search {
            top: 8px;
        }
    }
    .navbar-nav .megamenu-link-list {
        ul {
            @include media-breakpoint-up(md) {
                padding-top: 36px;
            }
        }
        ul li a {
            @include navigation(3);
            @include media-breakpoint-up(md) {
                @include navigation(4);
            }
        }
    }
}
.main-menu.menu-toggleable-left .top-category ~ .menu-subcategories-container .menu-subcategories > .dropdown-item > .dropdown-link {
    font-family: $secondary-font;
}