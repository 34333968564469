@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400&display=swap');

// Locally stored fonts (must be placed in static/fonts folder)
// Change this one variable, the rest should be handled in client core
// For italic fonts set the object key to 'normal'

$local-fonts: (
    "Roboto Condensed" : (
        300: "RobotoCondensed-Light",
        400: "RobotoCondensed-Regular",
        500: "RobotoCondensed-Medium"
    )
);

@import "~client_core/utilities/fonts";