@import "~client_core/product/detail";

.product-detail {
    .badge-product-container {
        display:unset;
    }
    
    .main-content-group {
        .card {
            .card-body {
                @include media-breakpoint-up(md) {
                    padding-left: 0;
                }
            }
        }
    }   
    
    .suggested-products-slider {
        border-top: 1px solid map-get($border, tertiary);
        .slider-title {
            @include header(6);
        }
    }
    .product-name {
        @include banner($pdp-name-header-value);
    }
    .attributes {
        .attribute {
            button {
                margin-right: $pdp-color-swatch-spacing;
                margin-bottom: $pdp-color-swatch-spacing;
            }
        }
    }
    .swatch-thumbnail.color-value {
        box-shadow: none;
    }
}

.main-content-group {
    .specification-container {
        .specification-inner-container {
            .product-specification {
                &-value,
                &-name {
                    font-family: $body-font;
                    @include paragraph-font-size();
                }
                &-name {
                    color: map-get($content, secondary);
                    font-weight: 300;
                }
                &-value {
                    font-weight: 500;
                }
            }
        }
    }
}