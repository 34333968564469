@import "~client_core/components/modal";
.modal {
    .modal-header {
        border-top-left-radius: $modal-border-radius;
        border-top-right-radius: $modal-border-radius;
    }
    .modal-footer {
        border-bottom-left-radius: $modal-border-radius;
        border-bottom-right-radius: $modal-border-radius;
    }
}
