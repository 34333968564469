@import "~client_core/mixins";

// apply navigation styles, usage: @include navigation(2) // n2 styles
@mixin navigation($navigationIndex) {
    $value: nth($navigation, $navigationIndex);

    font-family: nth($value, 2);
    font-feature-settings: $variable-settings;
    font-size: nth($value, 3);
    line-height: nth($value, 4);
    font-weight: nth($value, 5);
    letter-spacing: nth($value, 6);
    text-transform: nth($value, 7);
    font-style: nth($value, 8);

    @include media-breakpoint-up(lg) {
        font-size: nth($value, 9);
        line-height: nth($value, 10);
        font-weight: nth($value, 11);
        letter-spacing: nth($value, 12);
        text-transform: nth($value, 13);
        font-style: nth($value, 14);
    }
}