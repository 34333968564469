@import "~core/components/formFields";

.form-control {
    border-width: 0;
    border-bottom-width: $input-border-width;
    &.focus-visible {
        border-width: $input-border-width;
    }
}
.custom-select {
    padding-top: 0;
    padding-bottom: 0;
    background-image: url(../images/icons/icon-expand.svg); // this svg was generated from our icomoon font library
    &.is-invalid {
        background-image: url(../images/icons/icon-expand.svg);
    }
}
.quantity-stepper {
    border: 1px solid transparent;
    input, button {
        background: map-get($bg, secondary);
    }
}