@import "~client_core/components/slider";


.slider-container {
    &[data-slider-controls-position='top'] {
        .tns-controls {
            button {
                padding: $slider-suggested-products-button-padding;
                border-radius: 50%;
                height: $slider-button-width;
            }
            .suggested-products-slider & {
                button {
                    padding: $slider-suggested-products-button-padding;
                    height: $slider-button-width;
                }
            }
        }
    }
    .suggested-products-slider & {
        [data-controls='prev']:before {
            margin-left: -2px; //fix for icon svg positioning
        }
    }
}